// @flow
//Core
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { authorStyles } from '../styles';

const AuthorSec = ({
  image,
  name,
  surname,
  jobTitle,
  description,
}: {
  image: string,
  name: string,
  surname: string,
  jobTitle: string,
  description: string,
}) => {
  const styles = authorStyles();

  return (
    <section>
      <Typography variant="h6" className={styles.sectionTitle}>
        この記事を書いた人：
      </Typography>
      <div className={styles.authorSec}>
        <Grid container spacing={10} alignItems="flex-end">
          <Grid item xs={12} md={3}>
            <img src={image} alt="" className={styles.img} />
          </Grid>

          <Grid item xs={12} md={9}>
            <Typography variant="body1" className={styles.title}>
              {`${name} ${surname}`}
            </Typography>
            <Typography variant="body1" className={styles.subtitle}>
              {jobTitle}
            </Typography>
            <Typography variant="body1">{description}</Typography>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AuthorSec;
