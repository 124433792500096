/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const bannerStyles = makeStyles(() => ({
  bannerSec: {
    paddingTop: 79,
    paddingBottom: 85,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  title: {
    wordBreak: 'keep-all',
  },
}));

export const departmentsStyles = makeStyles(() => ({
  departmentsSec: {
    paddingBottom: 100,
  },
  title: {
    textAlign: 'center',
    marginBottom: 70,
  },
  departmentTitle: {
    marginTop: 10,
    marginBottom: 15,
    minHeight: 80,
  },
}));

export const itemsStyles = makeStyles(() => ({
  section: {
    padding: `0 0 115px`,
  },
  sectionMobile: {
    padding: `0 8px 115px 8px`,
  },
  title: {
    display: '-webkit-box',
    margin: '8px 0 !important',
    padding: '0',
    '-webkit-box-orient': 'vertical',
  },
  subtitle: {
    paddingTop: 8,
    paddingBottom: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  card: {
    width: 360,
    borderRadius: 16,
    background: 'transparent',
    position: 'relative',
  },
  cardMobile: {
    borderRadius: 16,
    height: 323,
    margin: 0,
  },
  cardImg: {
    aspectRatio: 360 / 207,
    borderRadius: 16,
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
  },
  tags: {
    marginRight: -16,
    marginBottom: 54,
    marginTop: 80,
  },
  tagsMobile: {
    margin: '64px 16px 54px 16px',
  },
  tag: {
    marginRight: 16,
    borderRadius: '20px',
  },
  tagMobile: {
    padding: '0px 12px !important',
  },
  itemTag: {
    fontSize: '12px !important',
    paddingTop: 16,
  },
  activeTag: {
    backgroundColor: '#EBE6E2!important',
  },
  loadMoreBtn: {
    marginTop: '80px !important',
    width: '100%',
    border: '1px solid #404042',
    textTransform: 'none',
    color: '#404042',
  },
  rightArrow: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    width: 16,
    height: 27,
  },
  newsContent: {
    minHeight: 125,
    overflow: 'hidden',
  },
  '@media screen and (max-width: 1024px)': {
    cardWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  '@media screen and (max-width: 786px)': {
    card: {
      width: '100%',
    },
    cardMobile: {
      marginBottom: 32,
    },
    grid: {
      justifyContent: 'center',
    },
  },
}));

export const previewStyles = makeStyles(() => ({
  previewSec: {
    paddingTop: 100,
    paddingBottom: 67,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 70,
    marginTop: 32,
  },
}));
