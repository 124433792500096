// @flow
//Core
import React from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
//Local
import arrowRight from '../../../../images/arrowRight.svg';
import { breadcrumbsStyles } from '../styles';

const BreadcrumbsSec = ({
  items,
  tag,
}: {
  items: Array<Object>,
  tag: {
    id: string,
    title: string,
  },
}) => {
  const styles = breadcrumbsStyles();

  const renderTitle = (index) => {
    return tag.title ? (
      <Typography className={styles.link} variant="subtitle1" key={index}>
        {tag.title}
      </Typography>
    ) : null;
  };

  const itemsJSX = items.map((item, index) =>
    index < items.length - 1 ? (
      <Typography
        variant="subtitle1"
        component={Link}
        to={item.url}
        key={index}
        className={styles.link}
      >
        {item.title}
      </Typography>
    ) : (
      renderTitle(index)
    )
  );

  return (
    <section>
      <Breadcrumbs
        separator={<img src={arrowRight} alt="" />}
        aria-label="breadcrumb"
        className={styles.breadcrumbs}
      >
        {itemsJSX}
      </Breadcrumbs>
    </section>
  );
};

export default BreadcrumbsSec;
