//Core
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import get from 'lodash.get';
//Local
import { recommendStyles } from '../styles';
import ColumnPostItem from '../../News/items/ColumnPostItem';

const ReccomendSec = ({ recommendedArticles }) => {
  const styles = recommendStyles();

  const recommendedArticlesJSX = recommendedArticles.map((item, index) => (
    <ColumnPostItem
      key={`recommend-post-${get(item, 'node.contentful_id')}`}
      itemUrl={`/column/article/${get(item, 'node.contentful_id')}`}
      title={get(item, 'node.title')}
      subtitle={get(item, 'node.subtitle')}
      tags={get(item, 'node.tags', [])
        .map((tag) => get(tag, 'title'))
        .join(', ')}
      previewImage={get(item, 'node.previewImage.file.url')}
      index={index}
    />
  ));

  return (
    <section className={styles.reccomendSec}>
      <Typography className={styles.title} variant="h3">
        こちらもオススメ
      </Typography>
      <Grid container spacing={3}>
        {recommendedArticlesJSX}
      </Grid>
    </section>
  );
};

export default ReccomendSec;
